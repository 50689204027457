import React from "react";
import NewsfeedCard from "../../Component/News cards/NewsfeedCard";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import useApiData from "../../UseDataApi";
import { Link } from "react-router-dom";

const Newsfeed = ({ Image, Headline, News }) => {
  const { data, loading, error } = useApiData(
    "https://api.octoberbreastcancer.com/api/News"
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="space-y-6 md:w-5/6 ">
      <div className="space-y-6">
        <h1 className="uppercase text-xl font-light">More on news feed</h1>
        <div className="md:w-[60rem]">
          <Swiper
            modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            // Set a default value
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            breakpoints={{
              // Set different settings for different screen sizes
              640: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 2,
              },
            }}
          >
            {data.map((item, index) => (
              <SwiperSlide key={index}>
                <Link
                  key={item.id}
                  to={{
                    pathname: `/newsdetail/${item.NewsId}`,
                    state: { data: item }, // Pass education data as state
                  }}
                  className="flex flex-col justify-start items-start text-start spacce-y-3 w-[25rem] rounded-lg "
                >
                  <div>
                    <img
                      src={`https://api.octoberbreastcancer.com/api/image/Get/News_feed/${item.NewsId}/${item.Image_1}`}
                      alt=""
                      className="rounded-t-lg w-full aspect-video object-cover"
                    />
                  </div>
                  <div className="bg-Secondary px-3 py-2 w-full h-40 z-50 rounded-b-lg ">
                    <h1 className="font-semibold text-2xl">{item.Title}</h1>
                    <p className="text-gray-600 pt-2 pb-5">
                      {item.Body.slice(0, 150)}...
                    </p>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="w-full flex justify-center items-center text-xl pb-5">
          <a
            href={"/newsfeed"}
            className="uppercase font-semibold text-Primary hover:text-black duration-75 ease-in-out"
          >
            show more
          </a>
        </div>
      </div>
    </div>
  );
};

export default Newsfeed;
