import logo from "../Assets/logo/Logo only.png";

const Loading = () => {
  return (
    <div className="flex flex-col justify-center items-center gap-6 h-screen">
      <img src={logo} alt="" className="loading-animation md:w-24 w-16" />
      <h1 className="font-semibold text-Secondary">Loading, Please wait...</h1>
    </div>
  );
};
export default Loading;
