import React, { useState } from 'react'
import logo from '../../Assets/logo/Logo only.png'
import { Link, NavLink } from 'react-router-dom'
import { CgMenuRight } from 'react-icons/cg'
import Avatar1 from '../../Assets/avatar/boy_4140070.png'
import { IoIosCloseCircleOutline } from 'react-icons/io'

const PhoneNavigate = ({ sticky,authenticated }) => {
  const [isOpen, setIsOpen] = useState(true)
  const handleOpen = () => {
    setIsOpen(!isOpen)
  }
  const nav = [
    { navigation: 'HOME', link: '/' },
    { navigation: 'CANCER EDUCATION', link: 'cancereducation' },
    { navigation: 'NEWS FEED', link: 'newsfeed' },
  ]
  return (
    <>
      <div
        className={
          "overflow-hidden sm:hidden bg-transparent w-full py-3 flex justify-between items-center z-50 text-black px-4"
        }
      >
        <Link to="Home" spy={true} smooth={true} offset={-100} duration={1000}>
          <img
            src={sticky ? logo : logo}
            alt=""
            className="w-10 cursor-pointer"
          />
        </Link>
        {authenticated ? (
          // Render avatar or profile component upon successful sign-in
          <div className="">
            <img src={Avatar1} alt="" className="w-10" />
          </div>
        ) : (
          <>
            <CgMenuRight onClick={handleOpen} className="text-3xl" />
            <div
              className={`fixed top-0 ${
                isOpen ? "right-0 transition-all" : "right-64 transition-all"
              } h-screen w-full bg-bgtransparent backdrop-blur-md text-black z-10 flex flex-col shadow-lg transition-all duration-300 transform translate-x-full`}
            >
              <IoIosCloseCircleOutline
                onClick={handleOpen}
                className="m-4 text-4xl hover:text-Secondary"
              />
              <div
                className={`flex flex-col justify-between p-4 text-md uppercase text-black gap-56`}
              >
                {" "}
                <div className="flex flex-col space-y-4 text-md uppercase">
                  {nav.map((items, index) => (
                    <Link
                      onClick={handleOpen}
                      key={index}
                      to={items.link}
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={1000}
                      className="hover:underline hover:text-Primary p-1 duration-200 ease-in-out cursor-pointer"
                    >
                      {items.navigation}
                    </Link>
                  ))}
                </div>
                <div className="flex flex-col justify-center items-start space-y-4">
                  <>
                    <NavLink
                      to="/signup"
                      className="bg-Primary px-4 py-2 font-bold text-white hover:bg-black duration-150 ease-in-out"
                    >
                      SIGN UP
                    </NavLink>
                    <NavLink
                      to="/signin"
                      className="hover:underline hover:text-Primary cursor-pointer duration-100 text-center ease-in-out"
                    >
                      SIGN IN
                    </NavLink>
                  </>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default PhoneNavigate
