// App.js
import Navigation from "./Component/Navigation/Navigate";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import CancerEducation from "./Pages/CancerEducation";
import NewsFeed from "./Pages/NewsFeed";
import Footer from "./Component/Footer/Footer";
import NewsDetail from "./Pages/NewsDetail";
import { useState } from "react";
import CancerEduDetails from "./Pages/CancerEduDetails";
import HomeApp from "./HomeApp";
import SignIn from "./Pages/SignIn";
import SignUp from "./Pages/SignUp";
import Acc_route from "./Account_route";
import { useAuth } from "./Authentication";
// import TokenExchange from "./TokenCheck";

function App() {
  const [showBackNav, setShowBackNav] = useState(true);
  const { authenticated, setAuthenticated } = useAuth();
  return (
    <div>
      {/* <TokenExchange/> */}
      <Router>
        <HomeApp />
        <Acc_route />
        <Routes>
          <Route path="/newsdetail/:id" element={<NewsDetail authenticated={authenticated}/>} />
          <Route path="/canceredudetail/:id" element={<CancerEduDetails />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
