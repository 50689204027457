import { Box, Button, TextField, Typography } from "@mui/material";
import React, {  useState } from "react";
import { FcGoogle } from "react-icons/fc";
import vector from "../Assets/vector/breast cancer vector@4x.png";
import logo from "../Assets/logo/Logo only.png";
import BackNav from "../Component/Navigation/BackNav";
import { useGoogleLogin } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../Authentication";
import axios from "axios";
import { MdOutlineCancel } from "react-icons/md";
import Error from "../Component/error";
import {  AiOutlineLoading3Quarters } from "react-icons/ai";

const SignIn = () => {
  const [formValues, setFormValues] = useState({ Username: "", Password: "" });
  const [errors, setErrors] = useState(false);
  const [message, setMessage] = useState();
  const [Emailpass, setEmailpass] = useState();
  const [forgetpass, setforgetpass] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading animation
  const { authenticated, setAuthenticated } = useAuth(false);
  const navigation = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (credentialResponse) => {
      console.log(credentialResponse);
      navigation("/");
    },
    onError: () => {
      console.log("Login Failed");
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading animation
    try {
      const response = await axios.post(
        "https://api.octoberbreastcancer.com/api/Login",
        {
          User_Name: formValues.Username,
          Password: formValues.Password,
        },
      );
      const token = response.data.Token;
      localStorage.setItem("accessToken", token);
      console.log(response.data);
      setLoading(false);
      setAuthenticated(true);
      navigation("/");
    } catch (err) {
      setErrors(true);
      setMessage("Error:", err);
      console.log(err);
      setLoading(false);
    }
  };
  const handleforgetpass = async (e) => {
    e.preventDefault()
    try {
      const response = await axios.patch(
        `https://api.octoberbreastcancer.com/api/User/ForgetPassword`,
        { Email: Emailpass }
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box className="bg-white">
      {/* <BackNav /> */}
      <Box className="bg-white flex flex-col md:flex-row md:justify-between justify-center md:items-start items-center w-full md:px-10 px-4 py-3 overflow-hidden space-y-4">
        <Box className="w-full rounded-lg">
          <h1 className="md:text-lg text-md md:w-5/6 md:text-start text-center">
            <span className="text-4xl font-bold text-Primary">
              Welcome Back!{" "}
            </span>
            We're thrilled to have you return.Sign in now to reconnect with our
            community, access your personalized content, and continue your
            journey with us.
          </h1>
          <Box className="md:flex hidden justify-center items-center">
            <img src={vector} className="w-full" />
          </Box>
        </Box>

        <Box className="bg-Secondary rounded-lg p-4">
          <Typography
            fontSize="2.5em"
            textTransform="capitalize"
            textAlign="center"
            sx={{ marginBottom: "3%" }}
            className="font-semibold"
          >
            Sign In
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              size="small"
              fullWidth
              type="text"
              required
              placeholder="Email or Username"
              value={formValues.Username}
              onChange={(e) =>
                setFormValues({ ...formValues, Username: e.target.value })
              }
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
                marginBottom: "5%",
              }}
            />

            <TextField
              size="small"
              fullWidth
              type="password"
              placeholder="Password"
              required
              value={formValues.Password}
              onChange={(e) =>
                setFormValues({ ...formValues, Password: e.target.value })
              }
              sx={{
                backgroundColor: "#ffffff",
                color: "#000000",
              }}
            />
            <button
              onClick={() => setforgetpass(true)}
              className="text-slate-500 hover:underline"
            >
              forget password?
            </button>
            {forgetpass && (
              <div
                className={`fixed flex justify-center items-center w-screen h-screen bg-black bg-opacity-40 top-0 right-0 z-50 overflow-hidden`}
              >
                <div className="relative px-5 py-6 bg-white rounded-lg overflow-hidden ">
                  <img
                    src={logo}
                    className="absolute w-32 h-32 -top-6 -left-6 opacity-30"
                    alt=""
                  />
                  <div className="flex justify-between items-start gap-9">
                    <div className="space-y-2">
                      {" "}
                      <h1 className="uppercase font-semibold text-xl">
                        Forget password
                      </h1>
                      <p className="w-72 text-slate-500">
                        Enter your email address and we will send you password
                        so you access your account
                      </p>
                    </div>
                    <MdOutlineCancel
                      onClick={() => setforgetpass(false)}
                      className="text-2xl hover:text-red-500 duration-150 ease-in-out"
                    />
                  </div>
                  <form
                    action=""
                    onSubmit={handleforgetpass}
                    className="mt-3 flex flex-col items-end gap-2"
                  >
                    <input
                      type="email"
                      placeholder="Email address"
                      name="Emailpass"
                      value={Emailpass}
                      onChange={(e) => setEmailpass(e.target.value)}
                      className="w-full p-2 outline-none rounded-md border-2 border-Secondary"
                    />
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        handleforgetpass();
                      }}
                      className="px-3 py-1 bg-Primary text-white rounded-md hover:bg-black duration-150 ease-in-out"
                    >
                      Send email
                    </button>
                  </form>
                </div>
              </div>
            )}
            <button
              type="submit"
              disabled={loading} // Disable button when loading
              className="bg-Primary rounded-md font-semibold hover:bg-black w-full py-2 uppercase text-lg flex justify-center items-center text-white mt-3 duration-150 ease-in-out"
            >
              {loading ? (
                <AiOutlineLoading3Quarters className="loading-animation text-2xl text-bold" />
              ) : (
                "Sign In"
              )}
            </button>
            {errors && (
              <Error
                error={message}
                handleClose={() => {
                  setErrors(false);
                }}
              />
            )}
            <Box className="flex justify-center items-center mt-3 gap-1">
              <span className="text-[#a3a3a3]">Don’t have an account? </span>
              <Link
                to="/signup"
                className="text-[#FE7FCD] hover:cursor-pointer font-semibold"
              >
                {" "}
                Sign up
              </Link>
            </Box>

            {/* <Box
              display="flex"
              justifyContent="space-evenly"
              alignItems="center"
              sx={{ marginTop: "5%", marginBottom: "5%" }}
            >
              <hr className="w-1/2 border-[#c69ab5]" />
              <Typography fontSize="1.2em" sx={{ m: "2%" }}>
                or
              </Typography>
              <hr className="w-1/2 border-[#c69ab5]" />
            </Box>

            <Button
              onClick={() => login()}
              fullWidth
              size="small"
              justifyContent="center"
              type="submit"
              className="bg-[#ffffff]"
              sx={{
                backgroundColor: "#ffffff",
                border: 1,
                borderColor: "#000000",
                color: "#000000",
              }}
            >
              <FcGoogle className="text-[1.7em] m-2" />
              Continue With Google
            </Button> */}
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default SignIn;
