import React from 'react'
import { useNavigate } from 'react-router-dom'

const Recentnewsfeed = ({NewsId,Image_1,Title,Body}) => {
  const nav = useNavigate()
  return (
    <div>
        <button
        onClick={()=>{nav(`/newsdetail/${NewsId}`)}}
        style={{ backgroundImage: `url('https://api.octoberbreastcancer.com/api/image/Get/News_feed/${NewsId}/${Image_1}')` }}
        className="w-full h-96 bg-cover rounded-lg bg-center object-contain"
      >
        <div className="Gradient flex flex-col justify-end items-start p-5 text-white w-full h-96 rounded-lg">
          <h1 className="md:text-4xl text-2xl text-start">{Title}</h1>
          <p className="md:text-xl text-md font-light text-start">
            {Body.slice(0,150)}....
          </p>
        </div>
      </button>
    </div>
  )
}

export default Recentnewsfeed
