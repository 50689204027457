import React from "react";
import logo from "../../Assets/logo/Logo with text.png";
import {
  FaFacebook,
  FaYoutube,
  FaLinkedinIn,
  FaCopyright,
} from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";

const Footer = () => {
  const nav = [
    { navigation: "HOME", link: "/" },
    { navigation: "CANCER EDUCATION", link: "/cancereducation" },
    { navigation: "NEWS FEED", link: "/newsfeed" },
  ];
  const icon = [
    {
      to: "https://www.facebook.com/profile.php?id=61554629918618",
      icons: FaFacebook,
    },
    {
      to: "https://www.linkedin.com/company/hiwotfana-cancer-treatment-center/",
      icons: FaLinkedinIn,
    },
    {
      to: "https://youtube.com/@HiwotFanaCancerTreatmentCenter?si=lG-nqIezTeEXohms",
      icons: FaYoutube,
    },
    {
      to: "https://www.instagram.com/octoberbreastcancersupport?igsh=NmN0Mzh6d3c3MTUx",
      icons: AiFillInstagram,
    },
  ];
  return (
    <div className="flex flex-col justify-center items-center gap-7 bg-black bg-opacity-85 text-white py-7">
      <img src={logo} alt="" className="w-24" />
      <div className="flex space-x-10">
        {nav.map((items, index) => (
          <div
            key={index}
            className="flex flex-row justify-center items-center"
          >
            <a
              href={items.link}
              className="hover:underline hover:text-Primary cursor-pointer duration-100 ease-in-out md:text-md text-sm"
            >
              {items.navigation}
            </a>
          </div>
        ))}
      </div>
      <div className="flex gap-6">
        {icon.map((items, index) => (
          <a key={index} href={items.to} className="">
            <items.icons className="text-xl" />
          </a>
        ))}
      </div>
      <div className="text-center text-sm font-light">
        <h1 className="flex items-center gap-1">
          <FaCopyright /> Copyright reserved 2024
        </h1>
        <h1>Develped by Tilet Tech</h1>
      </div>
    </div>
  );
};

export default Footer;
