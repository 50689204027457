import React from "react";
import DetailsCanceredu from "../Component/Details/DetailsCanceredu";
import useApiData from "../UseDataApi";
import { useParams } from "react-router-dom";
import Commentpost from "../Component/Like and comment/Commentpost";
import Loading from "../Component/loading";
import { useAuth } from "../Authentication";

const CancerEduDetails = ({authenticated}) => {
  const { id } = useParams(); // Get the ID parameter from the URL
  const {
    data: newsData,
    loading,
    error,
  } = useApiData(`https://api.octoberbreastcancer.com/api/blog/${id}`);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="md:px-10 px-4 py-4 overflow-hidden ">
      <div className="flex md:flex-row flex-col-reverse justify-between gap-4">
        <div className="">
          <div className="md:w-80 w-full">
          <Commentpost
            URLapi={`https://api.octoberbreastcancer.com/api/Blog/Comment/${id}`}
            URLget={`https://api.octoberbreastcancer.com/api/Blog/Get/Comment/${id}`}
          /></div>
        </div>
        <div className="flex-1">
          <h1 className="uppercase font-bold md:text-2xl text-xl text-end">
            cancer education detail
          </h1>
          {newsData && (
            <DetailsCanceredu
              BlogId={newsData.BlogId}
              youtube={newsData.Video_Link}
              Image_1={newsData.Image_1}
              Image_2={newsData.Image_2}
              Name={newsData.Author}
              Headline={newsData.Title}
              position={newsData.Position}
              canceredupara={newsData.Body}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CancerEduDetails;
