import { IoSend } from "react-icons/io5";
import userIcon from "../../Assets/icons/user_13976368.png";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import Error from "../error";

const Commentpost = ({ URLapi, URLget }) => {
  const { id } = useParams();
  const nav = useNavigate();
  const [commenttext, setCommentText] = useState("");
  const [Errors, setError] = useState(false);
  const [fetchdata, setFetchData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchComments = async () => {
    try {
      const response = await axios.get(URLget);
      setFetchData(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching comments:", error);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    fetchComments();
  }, [URLget]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(false); // Clear previous errors

    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error("Access token not found.");
        setError(true);
        return;
      }

      const response = await axios.post(
        URLapi,
        { comment_text: commenttext },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setCommentText(""); // Clear the input after successful submission
      fetchComments(); // Refetch comments to show the latest ones
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Error data:", error.response.data);
        console.error("Error status:", error.response.status);
        console.error("Error headers:", error.response.headers);

        if (error.response.status === 500) {
          setError(true);
        } else if (error.response.status === 401) {
          console.error("Unauthorized. Redirecting to sign in.");
          nav("/signin");
        } else {
          setError(true);
        }
      } else {
        console.error("Error message:", error.message);
        setError(true);
      }
    }
  };

  // const handleDelete = async (commentId) => {
  //   try {
  //     const token = localStorage.getItem("accessToken");
  //     if (!token) {
  //       console.error("Access token not found.");
  //       setError(true);
  //       return;
  //     }

  //     await axios.delete(
  //       `https://api.octoberbreastcancer.com/api/News/UnComment/${commentId}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     fetchComments(); // Refetch comments after deletion
  //   } catch (error) {
  //     console.error("Error deleting comment:", error);
  //     setError(true);
  //   }
  // };

  return (
    <div className="space-y-4 py-4">
      <h1 className="uppercase font-bold text-xl">Comment section</h1>
      <div className="h-[25rem] overflow-scroll">
        {loading ? (
          <p>Loading comments...</p>
        ) : (
          fetchdata.map((items, index) => (
            <div className="flex justify-between py-4" key={index}>
              <div className="flex items-center gap-4">
                <img src={userIcon} alt="user avatar" className="w-12 h-12 rounded-full" />
                <div>
                  <h1 className="font-semibold text-lg">{items.user.User_Name}</h1>
                  <p>{items.comment_text}</p>
                </div>
              </div>
              {/* <button onClick={() => handleDelete(items.CommentId)}>
                <AiOutlineDelete className="hover:text-red-500 text-xl" />
              </button>
              {Errors && <Error handleClose={() => setError(false)} />} */}
            </div>
          ))
        )}
      </div>
      <form onSubmit={handleSubmit} className="flex gap-2">
        <input
          type="text"
          name="commentsection"
          value={commenttext}
          onChange={(e) => setCommentText(e.target.value)}
          placeholder="Comment section"
          className="w-full px-2 py-1 outline-none border-[0.2rem] border-slate-200 rounded-lg text-lg"
          required
        />
        <button
          type="submit"
          className="text-Primary text-xl rounded-lg duration-150 ease-in-out hover:text-black"
        >
          <IoSend className="text-3xl" />
        </button>
      </form>
    </div>
  );
};

export default Commentpost;
