import React, { useState } from "react";
import img4 from "../../Assets/images/img4.jpg";
import Toggle from "../Like and comment/Toggle";
import VideoDisplay from "../Youtube/Videodisplay";

const DetailsCanceredu = ({
  youtube,
  BlogId,
  Image_1,
  Image_2,
  Name,
  Headline,
  position,
  canceredupara,
}) => {
  const [image, setimage] = useState(false);
  return (
    <div className="w-full space-y-3">
      <div className="flex flex-col justify-between">
        <div className="flex-1 pb-10">
        <VideoDisplay videoId={youtube}/>
        </div>
        <div className="flex-1 flex md:flex-row flex-col justify-between gap-2 w-full">
        {image ? (
            ""
          ) : (
            <img
              src={`https://api.octoberbreastcancer.com/api/image/Get/Blog/${BlogId}/${Image_1}`}
              alt=""
              className="w-full h-64 object-cover rounded-lg"
            />
          )}
          {image ? (
            ""
          ) : (
            <img
              src={`https://api.octoberbreastcancer.com/api/image/Get/Blog/${BlogId}/${Image_2}`}
              alt=""
              className="w-full h-64 object-cover rounded-lg"
            />
          )}
        </div>
      </div>
      <div className="w-full flex justify-end items-end">
        <Toggle />
      </div>
      <div className="space-y-5">
        <div className="">
          <h1 className="text-3xl font-bold">{Headline}</h1>
          <p className="text-slate-400">
            {Name} <br /> {position}
          </p>
        </div>
        <p className="w-full">{canceredupara}</p>
      </div>
    </div>
  );
};

export default DetailsCanceredu;
