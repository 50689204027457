import React, { useState, useEffect } from "react";
import { AiOutlineLike, AiFillLike } from "react-icons/ai";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import useApiData from "../../UseDataApi";

const Toggle = () => {
  const { id } = useParams();
  const nav = useNavigate();
  const [likeId, setLikeId] = useState(null);
  const [likeClicked, setLikeClicked] = useState(false);
  const { data, loading, error } = useApiData(
    `https://api.octoberbreastcancer.com/api/News/Get/Like/${id}`
  );

  useEffect(() => {
    if (data) {
      handleClick();
      setLikeClicked(true);
    } else {
      setLikeClicked(false);
    }
  }, [data]);

  const handleClick = async () => {
    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error("Access token not found.");
        nav("/signin");
        return;
      }

      if (!likeClicked) {
        // User hasn't liked the news, send a POST request to add the like
        const response = await axios.post(
          `https://api.octoberbreastcancer.com/api/News/Like/${id}`,
          null,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const storeId = response.data;
        setLikeId(storeId);
        console.log("Like ID set:", storeId);
      } else {
        // User has liked the news, send a DELETE request to remove the like
        if (!likeId) {
          console.error("Like ID not found.");
          return;
        }
        const response = await axios.delete(
          `https://api.octoberbreastcancer.com/api/News/UnLike/${likeId}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Like removed:", likeId);
        const storeId = response.data
        setLikeId(storeId);
      }
      setLikeClicked(!likeClicked);
    } catch (error) {
      console.error("Error:", error);
      nav("/signin");
      if (error.response && error.response.status === 401) {
        console.error(
          "Unauthorized. Check access token validity and permissions."
        );
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="flex flex-row justify-center items-center space-x-4 text-2xl">
      <button onClick={handleClick}>
        {likeClicked ? (
          <AiFillLike style={{ color: "red" }} />
        ) : (
          <AiOutlineLike />
        )}
        <h1 className="text-lg text-red-600">{data}</h1>
      </button>
    </div>
  );
};

export default Toggle;
