import {jwtDecode} from "jwt-decode";
import React, { createContext, useContext, useEffect, useState } from "react";

// Create context
const AuthContext = createContext();

// Auth provider component
export const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    console.log(token)
    
    if (token) {

      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        // Token is expired
        localStorage.removeItem("accessToken");
        setAuthenticated(false);
      } else {
        // Token is valid
        setAuthenticated(true);
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to consume authentication state
export const useAuth = () => useContext(AuthContext);
