import React from 'react';
import YouTube from 'react-youtube';

const VideoDisplay = ({ videoId }) => {
  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  return (
    <div className="relative w-full pb-[56.25%] h-0">
      <YouTube 
        videoId={videoId} 
        opts={opts} 
        className="absolute top-0 left-0 w-full h-full rounded-lg" 
        iframeClassName="w-full h-full " 
      />
    </div>
  );
};

export default VideoDisplay;
