import React from "react";
import { Link, useNavigate } from "react-router-dom";

const CardPost = ({ name, link, index, Title, Author }) => {
  const nav = useNavigate();
  return (
    <div className=" flex flex-col justify-start items-start py-4 rounded-lg gap-4 ">
      <div className="w-full flex flex-col justify-start items-start space-y-5">
        <Link to={link} className="space-y-2 text-start w-80">
          <h1 className="font-bold text-slate-300 text-3xl">0{index + 1}</h1>
          <div className="">
            <h1 className="text-2xl font-bold">{Title}</h1>
            <div className="text-slate-400 text-sm w-full ">
              <h1 className="font-bold">{Author}</h1>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CardPost;
