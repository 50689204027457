import React from "react";
import Toggle from "../Like and comment/Toggle";
import { Link, useNavigate } from "react-router-dom";
import useApiData from "../../UseDataApi";
import Loading from "../loading";
import Error from "../error";

const EduCards = ({data}) => {

  return (
    <div className="text-start flex flex-wrap overflow-y-scroll w-full gap-6 py-5">
      {data.map((item, index) => (
        <Link
          key={item.id}
          to={{
            pathname: `/canceredudetail/${item.BlogId}`,
            state: { eduData: item }, // Pass education data as state
          }}
          className="space-y-3"
        >
          <img
            src={`https://api.octoberbreastcancer.com/api/image/Get/Blog/${item.BlogId}/${item.Image_1}`}
            alt=""
            className="md:w-80 md:h-44 rounded-lg object-cover"
          />
          <div className="space-y-4 flex flex-col items-start">
            <h1 className="text-2xl font-bold">{item.Title}</h1>
            <div className="text-slate-400 text-sm w-full ">
              <h1 className="font-bold">{item.Author}</h1>
              <p className="text-Primary">{item.createdAt}</p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default EduCards;
