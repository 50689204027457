import React, { useEffect, useState } from "react";
import EduCards from "../Component/Education cards/EduCards";
import img5 from "../Assets/images/img5.jpg";
import { useNavigate } from "react-router-dom";
import CardPost from "../Component/TopPost/CardPost";
import axios from "axios";
import useApiData from "../UseDataApi";
import Loading from "../Component/loading";
import Error from "../Component/error";

const CancerEducation = () => {
  const { data, loading, error } = useApiData(
    "https://api.octoberbreastcancer.com/api/blog"
  );

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  if (error)
    return (
      <div>
        <Error error={error} />
      </div>
    );

  return (
    <div className="md:px-10 md:p-7 px-4 flex md:flex-row flex-col-reverse md:justify-between justify-start md:items-start items-start overflow-hidden md:gap-4 gap-10 md:space-y-0">
      <div className="overflow-y-scroll md:h-[60rem] h-screen">
        <h1 className="uppercase text-2xl font-semibold">cancer education</h1>
        <EduCards data={data} />
      </div>
      <div className="md:w-96">
        <div className="pt-3">
          <h1 className="uppercase text-lg text-slate-400 font-bold">
            popular Cancer education
          </h1>
          {(data.slice(0,5)).map((items, index) => (
            <CardPost
              link={`/canceredudetail/${items.BlogId}`}
              index={index}
              Title={items.Title}
              Author={items.Author}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CancerEducation;
