import React, { useEffect, useState } from "react";
import NewsfeedCard from "../Component/News cards/NewsfeedCard";
import logo from "../Assets/logo/Logo only.png";
import axios from "axios";
import Recentnewsfeed from "../Container/Recently/Recentnewsfeed";
import { useNavigate } from "react-router-dom";
import useApiData from "../UseDataApi";
import Loading from "../Component/loading";
import { BiError } from "react-icons/bi";
import Error from "../Component/error";

const NewsFeed = () => {
  const [errors,setError]=useState(false)
  const { data: newsData,loading, error } = useApiData("https://api.octoberbreastcancer.com/api/News");

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Error error={error} handleClose={()=>{setError(false)}}/>;
  }

  return (
    <div className="md:px-10 px-4 md:p-7">
      <div className="space-y-3 py-5">
        <h1 className="font-bold uppercase text-2xl">Recently</h1>
        <div className="md:grid flex flex-col grid-cols-2 gap-4">
         
            <>
              <div className="">
                <Recentnewsfeed
                NewsId={newsData[0].NewsId}
                  Image_1={newsData[0].Image_1}
                  Title={newsData[0].Title}
                  Body={newsData[0].Body}
                />
              </div>
              <div className="">
                <Recentnewsfeed
                NewsId={newsData[1].NewsId}
                  Image_1={newsData[1].Image_1}
                  Title={newsData[1].Title}
                  Body={newsData[1].Body}
                />
              </div>
              <div className="col-span-2">
                <Recentnewsfeed
                NewsId={newsData[2].NewsId}
                  Image_1={newsData[2].Image_1}
                  Title={newsData[2].Title}
                  Body={newsData[2].Body}
                />
              </div>
            </>
       
        </div>
      </div>
      <div className="space-y-3 py-6 overflow-hidden w-full h-screen mb-8 ">
        <h1 className="font-bold uppercase text-2xl">news feed</h1>
        <div className=" flex flex-col justify-center  items-center md:grid grid-cols-3  gap-5 overflow-scroll h-full pr-1">
          <NewsfeedCard data={newsData} />
        </div>
      </div>
    </div>
  );
};

export default NewsFeed;
