import React, { useEffect, useState } from "react";
import Toggle from "../Like and comment/Toggle";
import { Link } from "react-router-dom";
import useApiData from "../../UseDataApi";

const Cards = ({ data }) => {
  return (
    <>
      {data.map((items, index) => (
        <Link
          key={items.id}
          to={{
            pathname: `/newsdetail/${items.NewsId}`,
            state: { data: items }, // Pass education data as state
          }}
          className="flex flex-col justify-start items-start text-start  md:w-[25rem] rounded-lg "
        >
          <div>
            <img
              src={`https://api.octoberbreastcancer.com/api/image/Get/News_feed/${items.NewsId}/${items.Image_1}`}
              alt=""
              className="rounded-t-lg w-full aspect-video object-cover"
            />
          </div>
          <div className="bg-Secondary px-3 py-2 w-full md:h-40 z-50 rounded-b-lg ">
            <h1 className="font-semibold text-2xl">{items.Title}</h1>
            <p className="text-gray-600 pt-2 pb-5">
              {items.Body.slice(0, 150)}...
            </p>
          </div>
        </Link>
      ))}
    </>
  );
};

export default Cards;
