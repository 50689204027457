import React, { useEffect, useState } from "react";
import CardPost from "../Component/TopPost/CardPost";
import Canceredu from "../Container/Home/Canceredu";
import Newsfeed from "../Container/Home/News";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import useApiData from "../UseDataApi";
import Loading from "../Component/loading";
import Error from "../Component/error";

const Home = () => {
  const nav = useNavigate();
  const [errors, setError] = useState(false);
  const {
    data: eduData,
    loading,
    error,
  } = useApiData("https://api.octoberbreastcancer.com/api/Blog");
  const { data: NewsData } = useApiData(
    "https://api.octoberbreastcancer.com/api/News"
  );

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  if (error)
    return (
      <div>
        <Error
          error={error}
          handleClose={() => {
            setError(false);
          }}
        />
      </div>
    );

  return (
    <div className="md:px-10 px-4 p-7">
      <h1 className="uppercase text-2xl font-bold">cancer education</h1>
      <div className="flex flex-col justify-between md:flex-row w-full">
        <div className="pt-3">
          <h1 className="uppercase text-lg text-slate-400 font-bold">
            popular Cancer education
          </h1>
          {eduData.slice(0, 5).map((items, index) => (
            <CardPost
              link={`/newsdetail/${items.BlogId}`}
              index={index}
              Title={items.Title}
              Author={items.Author}
            />
          ))}
        </div>
        <Canceredu edu={eduData} />
      </div>
      <div className="flex flex-col justify-between md:flex-row w-full gap-4">
        <div className="pt-3">
          <h1 className="uppercase text-lg text-slate-400 font-bold">
            popular News Feed
          </h1>
          {NewsData.slice(0, 5).map((items, index) => (
            <CardPost
              link={`/newsdetail/${items.NewsId}`}
              index={index}
              Title={items.Title}
              Author={items.Author}
            />
          ))}
        </div>
        <Newsfeed />
      </div>
    </div>
  );
};

export default Home;
