import React from "react";
import EduCards from "../../Component/Education cards/EduCards";
import img1 from "../../Assets/images/img1.jpg";
import img2 from "../../Assets/images/img2.jpg";
import {
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { useNavigate } from "react-router-dom";

const Canceredu = ({ edu }) => {
  const nav = useNavigate()
  return (
    <div className=" py-5 space-y-6 md:w-3/4 w-full">
      <div className=" space-y-3 ">
        <Swiper
          modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={50}
          slidesPerView={1}
          loop={true}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          pagination={{ clickable: true }}
        >
          {edu.map((items, index) => (
            <SwiperSlide key={index}>
              <button onClick={()=>{nav(`/canceredudetail/${items.BlogId}`)}} className="Gradient md:h-screen h-96 text-start md:px-10 px-4 py-2 rounded-lg ">
                <img
                  src={`https://api.octoberbreastcancer.com/api/image/Get/Blog/${items.BlogId}/${items.Image_1}`}
                  alt=""
                  className="md:w-full w-[100%] h-full md:bg-cover object-cover bg-center aspect-auto absolute right-0 top-0 -z-50"
                />
                <div className="flex flex-col h-full justify-end items-start z-50">
                  <p className="md:text-4xl text-2xl font-semibold text-white">
                    {items.Title}
                  </p>
                  <h4 className="text-white ">{items.Body.slice(0, 199)}...</h4>
                </div>
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="w-full flex justify-end items-end text-xl">
        <a
          href={"/cancereducation"}
          className="uppercase font-semibold text-Primary hover:text-black duration-75 ease-in-out"
        >
          show more
        </a>
      </div>
    </div>
  );
};

export default Canceredu;
