import React, { useState } from "react";
import Toggle from "../Like and comment/Toggle";
import noimage from "../../Assets/icons/Mesa de trabajo 1@4x.png";

const Detailnews = ({
  NewsId,
  Image_1,
  Image_2,
  Image_3,
  Title,
  Body,
  Date,
}) => {
  const [image, setImage] = useState(false);

  if (
    `https://api.octoberbreastcancer.com/api/image/Get/News_feed/${NewsId}/${Image_2}` ==
    null
  ) {
    setImage(true);
    return(
      <img src={noimage} alt="" />
    )
  }
  return (
    <div className="py-3 md:w-full">
      <div className="space-y-4">
        <h1 className="uppercase font-bold text-sm text-Secondary">Headline</h1>
        <h1 className="font-semibold text-4xl ">{Title}</h1>
        <div className="w-full flex flex-col justify-between gap-2">
          <div className="md:w-full flex md:flex-row flex-col md:justify-between justify-center items-center">
            <div className="md:flex-1 md:mr-2">
              <img
                src={`https://api.octoberbreastcancer.com/api/image/Get/News_feed/${NewsId}/${Image_1}`}
                alt=""
                className="w-full md:h-96 h-64 object-cover rounded-lg mb-4"
              />
            </div>
            <div className="md:flex-1 md:ml-2">
              
                <img
                  src={`https://api.octoberbreastcancer.com/api/image/Get/News_feed/${NewsId}/${Image_2}`}
                  alt=""
                  className="w-full md:h-64 object-cover rounded-lg mb-4"
                />
            
                <img
                  src={`https://api.octoberbreastcancer.com/api/image/Get/News_feed/${NewsId}/${Image_3}`}
                  alt=""
                  className="w-full md:h-64 object-cover rounded-lg mb-4"
                />
             
            </div>
          </div>
          <div className="flex w-full justify-end items-end">
            <Toggle />
          </div>
          <p>{Body}</p>
        </div>
      </div>
    </div>
  );
};

export default Detailnews;
