import React, { useState } from "react";
import Detailnews from "../Component/Details/Detailnews";
import { useNavigate, useParams } from "react-router-dom";
import useApiData from "../UseDataApi";
import Commentpost from "../Component/Like and comment/Commentpost";
import axios from "axios";
import noimage from "../Assets/icons/Mesa de trabajo 1@4x.png";
import Loading from "../Component/loading";
import Error from "../Component/error";

const NewsDetail = ({ authenticated }) => {
  const { id } = useParams();
  const nav = useNavigate();
  const [errors, setError] = useState(false);
  const { data: sidepanel } = useApiData(
    "https://api.octoberbreastcancer.com/api/News"
  );
  const {
    data: NewsData,
    loading,
    error,
  } = useApiData(`https://api.octoberbreastcancer.com/api/News/${id}`);

  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  if (error)
    return (
      <div>
        <Error
          error={error}
          handleClose={() => {
            setError(false)
          }}
        />
      </div>
    );
  const filteredSidepanel = sidepanel.filter(
    (item) => item.NewsId !== NewsData.NewsId
  );
  return (
    <div className=" md:px-10 px-4 overflow-hidden">
      <div className="flex md:flex-row flex-col-reverse justify-between gap-4">
        <div className=" ">
          <div className="py-3 md:w-80 w-full">
            <h1 className="uppercase text-xl font-bold">Related Topics</h1>
            {filteredSidepanel.map((items, index) => (
              <button
                onClick={() => {
                  nav(`/newsdetail/${items.NewsId}`);
                }}
                key={index}
                className="py-4 space-y-3 text-start w-full"
              >
                <h1 className="uppercase text-sm text-Secondary font-semibold">
                  news feed
                </h1>
                <p className="text-xl tracking-wider ">{items.Title}</p>
                <div className="w-full h-0.5 bg-slate-400" />
              </button>
            ))}
          </div>

          <div className="md:w-80 w-full">
            <Commentpost
              URLapi={`https://api.octoberbreastcancer.com/api/News/Comment/${id}`}
              URLget={`https://api.octoberbreastcancer.com/api/News/Get/Comment/${id}`}
              // delURL={`https://api.octoberbreastcancer.com/api/News/UnComment/${id}`}
            />
          </div>
        </div>
        <div className="">
          <h1 className="uppercase font-bold md:text-2xl text-xl text-end">
            News Feed
          </h1>
          {NewsData && (
            <Detailnews
              NewsId={NewsData.NewsId}
              Image_1={NewsData.Image_1}
              Image_2={NewsData.Image_2}
              Image_3={NewsData.Image_3}
              Title={NewsData.Title}
              Body={NewsData.Body}
              Date={NewsData.Date}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
