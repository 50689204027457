import { Route, Router, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import CancerEducation from "./Pages/CancerEducation";
import NewsFeed from "./Pages/NewsFeed";
import Navigation from "./Component/Navigation/Navigate";
import useSticky from "./Usesticky";
import PhoneNavigate from "./Component/Navigation/PhoneNavigate";
import { useAuth } from "./Authentication";
import { useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import TokenExchange from "./TokenCheck";

const HomeApp = () => {
  const isSticky = useSticky(500);
  const { authenticated, setAuthenticated } = useAuth();
  

  return (
    <div>
      <Navigation sticky={isSticky} authenticated={authenticated}/>
      <PhoneNavigate sticky={isSticky} authenticated={authenticated}/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/cancereducation" element={<CancerEducation/>} />
        <Route path="/newsfeed" element={<NewsFeed />} />
      </Routes>
    </div>
  );
};
export default HomeApp;
